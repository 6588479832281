import { useContext, useEffect, useMemo, useState } from "react";
import {
  GoogleAuthProvider,
  OAuthProvider,
  signInWithRedirect,
  signInWithPopup,
  sendSignInLinkToEmail,
} from "firebase/auth";
import {
  Button,
  Stack,
  Typography,
  Box,
  Divider,
  TextField,
} from "@mui/material";
import { Google, Apple } from "@mui/icons-material";
import { auth } from "../firebase/firebase.js";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AuthContext } from "src/utils/AuthContext.js";
import { getPageRoute } from "src/services";
import Footer from "./blocks/Footer.js";
import { useSnackbarContext } from "src/utils/SnackbarContext.js";
import PermContact from "src/assets/svg/permcontact.js";
import Microsoft from "src/assets/svg/microsoft.js";
import { debounce } from "lodash";
import { isInvalidEmail } from "src/utils/Utils.js";
import { userAgentEmbeddedCheck } from "src/utils/userAgentEmbeddedCheck.js";
import { Helmet } from "react-helmet";
import { LoadingButton } from "./blocks/LoadingButton.js";
import { useMutation } from "@tanstack/react-query";

const googleProvider = new GoogleAuthProvider();
const appleProvider = new OAuthProvider("apple.com");
const microsoftProvider = new OAuthProvider("microsoft.com");

googleProvider.addScope("profile");
googleProvider.addScope("email");

function SignIn({ dialog }) {
  const {
    setSnackbarOpen,
    setSnackbarMessage,
    setSnackbarSeverity,
    showSnackBar,
  } = useSnackbarContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [sentLink, setSentLink] = useState(false);

  const { user, checkSignIn } = useContext(AuthContext);

  const clearSiginWithEmailTracking = () => {
    const newSearchParams = new URLSearchParams(window.location.search);
    newSearchParams.delete("email");
    newSearchParams.delete("apiKey");
    newSearchParams.delete("oobCode");
    newSearchParams.delete("mode");
    newSearchParams.delete("lang");
    setSearchParams(newSearchParams);
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${newSearchParams.toString()}`
    );
  };

  const signInWithProvider = async (provider) => {
    clearSiginWithEmailTracking(); // remove a possible old sign in with email tracking
    try {
      const result = await signInWithPopup(auth, provider);
      await checkSignIn(false, result);
    } catch (err) {
      console.log(err);
      let errorMessage = err?.response?.data?.message;
      showSnackBar(
        setSnackbarOpen,
        setSnackbarMessage,
        setSnackbarSeverity,
        errorMessage ?? "There was an issue signing in. Make sure popups aren't blocked by your browser.",
        "error"
      );
    }
  };

  useEffect(() => {
    checkSignIn(true, null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signInWithGoogle = async () => {
    return signInWithProvider(googleProvider);
  };

  const signInWithApple = async () => {
    return signInWithProvider(appleProvider);
  };

  const signInWithMicrosoft = async () => {
    return signInWithProvider(microsoftProvider);
  };

  const isSubscribing = useMemo(() => {
    if (searchParams.get("forward")) {
      const forward = searchParams.get("forward");
      return forward && forward.includes("subscribing");
    } else if (searchParams.get("subscribing")) {
      return true;
    }
  }, [searchParams]);

  useEffect(() => {
    if (user) {
      // handle redirect post sign in
      const forward = searchParams.get("forward");

      if (forward) {
        setTimeout(() => {
          navigate(`${forward}`);
        }, 2000);
      } else {
        navigate(getPageRoute("home", "HOME"));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const { mutateAsync: handleSendEmail, isLoading } = useMutation(async ({ resend }) => {
    clearSiginWithEmailTracking(); // remove a possible old sign in with email tracking
    const newSearchParams = new URLSearchParams(window.location.search);
    newSearchParams.set("email", email);
    try {
      await sendSignInLinkToEmail(auth, email, {
        url:
          window.location.origin +
          window.location.pathname +
          "?" +
          newSearchParams.toString(),
        handleCodeInApp: true,
      });
      setSentLink(true);
      showSnackBar(
        setSnackbarOpen,
        setSnackbarMessage,
        setSnackbarSeverity,
        resend
          ? "Link resent, check your inbox."
          : "Link sent, check your inbox.",
        "success"
      );
    } catch (err) {
      console.error(err);
      let errorMessage = err?.response?.data?.message;
      showSnackBar(
        setSnackbarOpen,
        setSnackbarMessage,
        setSnackbarSeverity,
        errorMessage ?? "Failed to send email.",
        "error"
      );
    }
  });

  const labels = {
    isSubscribing: {
      title: "Choose your Calendar",
      subtitle: "Events will be automatically synced.",
    },
    default: {
      title: "Sign in to Stanza",
      subtitle: "Calendars for everyone.",
    },
  }[isSubscribing ? "isSubscribing" : "default"];

  const mediumContainer = dialog
    ? {
        width: "auto",
        padding: 0,
        margin: "-0.5rem 0 0 0",
        textAlign: "center",
      }
    : {
        margin: "auto",
        maxWidth: "400px",
        textAlign: "center",
        p: 6,
      };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: dialog ? "auto" : "100vh",
      }}
    >
      <Box sx={mediumContainer}>
        {!sentLink ? (
          <SignInContent
            signInWithGoogle={signInWithGoogle}
            signInWithApple={signInWithApple}
            signInWithMicrosoft={signInWithMicrosoft}
            isLoading={isLoading}
            labels={labels}
            email={email}
            setEmail={setEmail}
            handleSendEmail={handleSendEmail}
          />
        ) : (
          <SentLinkSuccessfully
            email={email}
            isLoading={isLoading}
            handleSendEmail={handleSendEmail}
          />
        )}
      </Box>
      {!dialog && <Footer />}
    </div>
  );
}

const isInvalidEmailDebounced = debounce((setIsInvalidForm, email) => {
  setIsInvalidForm(isInvalidEmail(email));
}, 300);

const SignInContent = ({
  signInWithGoogle,
  signInWithApple,
  signInWithMicrosoft,
  isLoading,
  labels,
  email,
  setEmail,
  handleSendEmail,
}) => {
  const [isInvalidForm, setIsInvalidForm] = useState(false);

  useEffect(() => {
    isInvalidEmailDebounced(setIsInvalidForm, email);
  }, [email]);

  return (
    <>
      <Helmet>
        <title>Sign In - Stanza</title>
        <meta name="description" content="Sign in to Stanza." />
      </Helmet>
      <PermContact />
      <Typography variant={"h2"} sx={{ mt: 1 }}>
        {labels.title}
      </Typography>
      <Typography variant={"subtitle2"} sx={{ mb: 2 }} color="text.secondary">
        {labels.subtitle}
      </Typography>
      <Stack spacing={2.5}>
        <Button
          variant="outlined"
          size="grown"
          startIcon={<Google />}
          onClick={signInWithGoogle}
          sx={{
            width: "100%",
            gap: { xs: 1, md: 0 },
          }}
        >
          Continue with Google
        </Button>
        <Button
          variant="outlined"
          size="grown"
          startIcon={<Apple />}
          onClick={signInWithApple}
          sx={{
            width: "100%",
            gap: { xs: 1, md: 0 },
          }}
        >
          Continue with Apple
        </Button>
        <Button
          variant="outlined"
          size="grown"
          startIcon={<Microsoft style={{ marginLeft: "4px" }} />}
          onClick={signInWithMicrosoft}
          sx={{
            width: "100%",
            gap: { xs: 1, md: 0 },
          }}
        >
          Continue with Microsoft
        </Button>
      </Stack>
      <Divider sx={{ my: 2.5 }}>
        <Typography variant="subtitle2">Or with email</Typography>
      </Divider>
      <Stack spacing={2.5} sx={{ pb: 2.5 }}>
        <TextField
          type="email"
          data-testid="email-signin"
          value={email}
          label="Email Address"
          variant="standard"
          onChange={(e) => setEmail(e.target.value.toLowerCase())}
        />
        <LoadingButton
          variant="contained"
          onClick={() => handleSendEmail({ resend: false })}
          size="grown"
          sx={{ width: "100%" }}
          loading={isLoading}
          disabled={isInvalidForm}
        >
          Send sign-in link
        </LoadingButton>
      </Stack>
    </>
  );
};

const SentLinkSuccessfully = ({ email, handleSendEmail, isLoading }) => {
  const [resendTimeout, setResendTimeout] = useState(30);

  useEffect(() => {
    if (resendTimeout === 0) return;
    const timeout = setTimeout(() => {
      setResendTimeout(resendTimeout - 1);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [resendTimeout]);

  return (
    <>
      <PermContact />
      <Typography variant={"h2"} sx={{ mt: 1 }}>
        Check your inbox
      </Typography>
      <Typography
        variant={"subtitle2"}
        sx={{ mb: 2.5, mt: 2 }}
        color="text.secondary"
      >
        To finish sign in, use the link sent to
        <br />
        <b style={{ color: "#000000" }}>{email}</b> <br />
      </Typography>
      <Typography variant={"subtitle2"} sx={{ mb: 2.5 }} color="text.secondary">
        Check spam before requesting another link.
      </Typography>
      <LoadingButton
        variant="outlined"
        onClick={() => {
          setResendTimeout(30);
          handleSendEmail({ resend: true });
        }}
        size="grown"
        loading={isLoading}
        sx={{ width: "100%", mb: 2.5 }}
        disabled={resendTimeout > 0}
      >
        {resendTimeout === 0
          ? "Resend the link"
          : `0:${String(resendTimeout).padStart(2, "0")}`}
      </LoadingButton>
    </>
  );
};

export default userAgentEmbeddedCheck(SignIn);
