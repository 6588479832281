import { useContext, useCallback } from "react";
import { Box, Typography, Icon, IconButton, Tooltip } from "@mui/material";
import { ContactSupportOutlined } from "@mui/icons-material";
import { AuthContext } from "src/utils/AuthContext.js";
import { useSnackbarContext } from "src/utils/SnackbarContext.js";
import { useCheckSubscriptionStatus, copyCalendarLinkToClipboard } from 'src/utils/Utils.js';
import { useTheme } from "@emotion/react";

const CalendarAddedSuccessfully = ({ displayMode, sourceCalendar, sx }) => {
    const { setSnackbarOpen, setSnackbarMessage, setSnackbarSeverity, showSnackBar } = useSnackbarContext();
    const { user, refetchAuth } = useContext(AuthContext);
    const { inGroup, subscribed } = useCheckSubscriptionStatus({ user, calendar: sourceCalendar });
    const copyToClipboard = useCallback(() => {
        copyCalendarLinkToClipboard({ inGroup, user, calendar: sourceCalendar, showSnackBar, setSnackbarOpen, setSnackbarMessage, setSnackbarSeverity })
    }, [inGroup, sourceCalendar, setSnackbarMessage, setSnackbarOpen, setSnackbarSeverity, showSnackBar, user]);
    const theme = useTheme();

    const title = {
        "added": "You should have finished subscribing to your calendar app.",
        "downloadGCalReminderConfirmation": "Your reminder is now set.",
        "oneTimeDownloadConfirmation": "Your one-time download is complete.",
    }[displayMode];

    const description = {
        "downloadGCalReminderConfirmation": `Follow the instructions in the reminder to subscribe to "${sourceCalendar?.name}" on your desktop, then sync it with the Google Calendar app on your phone.`,
        "oneTimeDownloadConfirmation": "Note: These events will not auto-update in your calendar app. You'll need to resubscribe to see the latest events.",
    }[displayMode];

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            mt: 3,
            gap: 1,
            ...sx,
        }}
            data-testid="calendar-added-successfully"
        >
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                    <Icon baseClassName="material-symbols-outlined" sx={{ fontWeight: "300", fontSize: "1.8rem", color: "success.main" }}>check</Icon>
                    <Typography variant="h2">{title}</Typography>
                </Box>
                <Tooltip title="Support">
                    <IconButton
                        component="a"
                        href={`https://stanzacal.substack.com/p/troubleshooting`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <ContactSupportOutlined fontSize="small" sx={{ color: `${theme.palette.text.secondary}` }} />
                    </IconButton>
                </Tooltip>
                {/*  <ShareDialog calendar={lastCalendarAdded} handle={lastCalendarAdded.handle} as="Button-Small" /> */}
            </Box>
            {displayMode === "added" ? (
                <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
                If you weren't redirected to your calendar app,
                {' '}<span
                    onClick={copyToClipboard}
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                >
                    copy this link
                </span>{' '}
                to import {sourceCalendar.name} into your calendar.
                </Typography>
            ) : (
                <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
                {description}
                </Typography>
            )}
        </Box >
    )
}

export default CalendarAddedSuccessfully;