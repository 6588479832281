import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ImportCalendar from "./components/ImportCalendar.js";
import Home from "./components/Home.js";
import Timeline from "./components/Timeline.js";
import CalendarSettings from "./components/CalendarSettings.js";
import SignIn from "./components/SignIn.js";
import Library from "./components/Library.js";
import Search from "./components/Search.js";
import Privacy from "./components/Privacy.js";
import Terms from "./components/Terms.js";
import {
  ProtectedRoute,
  ProtectedCreatorUpgradeRoute,
} from "./utils/ProtectedRoute.js";
import ConsumerUpgrade from "./components/upgrades/ConsumerUpgrade.js";
import CreatorUpgrade from "./components/upgrades/CreatorUpgrade.js";
import HowToImport from "./components/instructions/HowToImport.js";
import CreatorMailingListUpgrade from "./components/upgrades/CreatorMailingListUpgrade.js";
import GoogleCalendarExperience from "./components/experiences/GoogleCalendarExperience.js";
import { TimelineEmbedded } from "./components/TimelineEmbedded.js";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { getPageRoute } from "src/services";
import { PreventBackButton, BackNavigationPosition } from "src/utils/NavigationUtils.js";
import { NavigationManager } from "src/utils/NavigationManager.js";
import { useEffect } from "react";

function App() {
  /*
    Refetch on window focus or reconnect
    keep previous data until cacheTime.
  */
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: Infinity,
        cacheTime: 1000 * 60 * 10, // 10 minutes
        keepPreviousData: true,
        refetchOnWindowFocus: true,
        refetchOnReconnect: true,
        onError: (error) => {
          console.error("Query error:", error);
        },
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <PreventBackButton />
        <NavigationManager />
        <BackNavigationPosition />
        <Routes>
          <Route path={getPageRoute("home", "HOME")} element={<Home />} />
          <Route path={getPageRoute("chat", "SEARCH")} element={<Search />} />
          <Route path={getPageRoute("chat", "SEARCH_TAB")} element={<Search />} />
          <Route
            path={getPageRoute("calendar", "LEGACY_TIMELINE")}
            element={<Timeline />}
          />
          <Route
            path={getPageRoute("calendar", "LEGACY_SUBSCRIPTION")}
            element={<Timeline />}
          />
          <Route
            path={getPageRoute("calendar", "LEGACY_SCHEDULES")}
            element={<Timeline />}
          />
          <Route
            path={getPageRoute("calendar", "ALL_EVENTS")}
            element={<Timeline />}
          />
          <Route
            path={getPageRoute("calendar", "CALENDAR_EVENTS")}
            element={<Timeline />}
          />
          <Route
            path={getPageRoute("calendar", "GROUP_EVENTS")}
            element={<Timeline />}
          />
          <Route
            path={getPageRoute("calendar", "PREVIEW_CALENDAR")}
            element={<Timeline preview={true} />}
          />
          <Route
            path={getPageRoute("calendar", "PREVIEW_CALENDAR_EVENTS")}
            element={<Timeline preview={true} />}
          />
          <Route
            path={getPageRoute("calendar", "CALENDAR_SETTINGS")}
            element={<ProtectedRoute Component={CalendarSettings} />}
          />
          <Route
            path={getPageRoute("calendar", "IMPORT")}
            element={
              <ProtectedRoute
                Component={() => (
                  <ProtectedCreatorUpgradeRoute Component={ImportCalendar} />
                )}
              />
            }
          />
          <Route path={getPageRoute("auth", "SIGNIN")} element={<SignIn />} />
          <Route
            path={getPageRoute("library", "LIBRARY")}
            element={<ProtectedRoute Component={Library} />}
          />
          <Route
            path={getPageRoute("upgrade", "UPGRADE_PLUS")}
            element={<ProtectedRoute Component={ConsumerUpgrade} />}
          />
          <Route
            path={getPageRoute("upgrade", "UPGRADE_CREATOR")}
            element={<ProtectedRoute Component={CreatorUpgrade} />}
          />
          <Route
            path={getPageRoute("help", "HELP_IMPORT")}
            element={<ProtectedRoute Component={HowToImport} />}
          />
          <Route
            path={getPageRoute("upgrade", "UPGRADE_CREATOR_MAILING_LIST")}
            element={<ProtectedRoute Component={CreatorMailingListUpgrade} />}
          />
          <Route path={getPageRoute("privacy", "PRIVACY")} element={<Privacy />} />
          <Route path={getPageRoute("terms", "TERMS")} element={<Terms />} />
          <Route
            path={getPageRoute("experiences", "SUBSCRIBING_GOOGLE_CALENDAR")}
            element={<ProtectedRoute Component={GoogleCalendarExperience} />}
          />
          <Route
            path={getPageRoute("embedded", "EMBEDDED_TIMELINE_EVENTS")}
            element={<TimelineEmbedded />}
          />
          {/* Test route for embedded timeline (debug webcomponent locally) */}
          {process.env.NODE_ENV !== "production" && (
            <Route
              path="/timeline_embedded_test"
              element={<>
                <div dangerouslySetInnerHTML={{
                  __html: `
                <div style="width: 90%; height: 231px; border: 1px solid #D9D9D9; border-radius: 8px;">
                  <stanza-timeline handle="nba-warriors" calendarId="53ae0d467f33ed8a06bd0421" direction="horizontal" />
                </div>
                <div style="width: 300px; height: 700px; border: 1px solid #D9D9D9; border-radius: 8px; margin-top: 2rem;">
                  <stanza-timeline handle="nba-warriors" calendarId="53ae0d467f33ed8a06bd0421" direction="vertical" />
                </div>
                `}} />
              </>}
            />
          )}
        </Routes>
      </Router>
      {/*     <ReactQueryDevtools initialIsOpen={false} />*/}
    </QueryClientProvider >
  );
}

export default App;
