import React, { useContext } from "react";
import { Typography, Box, Button, Stack, Icon, Divider } from "@mui/material";
import { AuthContext } from "src/utils/AuthContext.js";
import Navbar from "../blocks/Navbar.js";
import axios from "axios";
import { useSearchParams, useNavigate } from "react-router-dom";
import { getApiRoute, getPageRoute } from "src/services";
import Footer from "../blocks/Footer.js";
import { useTheme } from "@emotion/react";
import { useDimensions } from "src/utils/useDimensions.js";
import { ContainerCloseButton } from "./ContainerCloseButton.js";
import { CommentsList } from "./CommentsList.js";
import User1 from 'src/assets/users/user1.png';
import User2 from 'src/assets/users/user2.png';
import User3 from 'src/assets/users/user3.png';
import User4 from 'src/assets/users/user4.png';
import { customEvent } from "src/utils/gtag.js";
import { TimeSaveBlock } from "./TimeSaveBlock.js";
import { useStripeCheckout } from "src/services/mutations/useStripeCheckout.js";
import { LoadingButton } from "src/components/blocks/LoadingButton.js";

function ConsumerUpgrade() {
  const user = useContext(AuthContext).user;
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const { isSmallScreen } = useDimensions();
  const navigate = useNavigate();
  const { mutateAsync: getStripeCheckout, isLoading } = useStripeCheckout();
  const isLifeTimeMember = user?.lifetimeMember;

  const handleUpgrade = async () => {
    customEvent({
      name: 'upgrade_plus_checkout',
      category: 'Upgrade',
      label: 'Upgrade to Plus Checkout Click',
      data: {
        user_id: user._id,
        user_email: user.email,
        user_handle: user.handle ?? 'no_handle',
      },
    });
    let forward = searchParams.get("forward");
    let successUrl;
    if (forward) {
      successUrl = forward;
    } else {
      successUrl = "/";
    }
    const successUrlSearchParams = new URLSearchParams(successUrl.split("?")?.[1] ?? "");
    successUrlSearchParams.set("success", "true");
    successUrl = `${successUrl}?${successUrlSearchParams.toString()}`
    const response = await getStripeCheckout({ type: "consumer", successUrl });
    window.location.href = response.data.url;
  };

  const handleGoHome = () => {
    navigate(getPageRoute("home", "HOME"));
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Navbar user={user} />
      <Box
        id="container"
        sx={{ margin: "auto", maxWidth: { xs: '100%', sm: "600px" }, position: "relative", p: 2, textAlign: "center" }}
      >
        {isSmallScreen && <ContainerCloseButton />}
        <Icon baseClassName="material-symbols-outlined" sx={{ display: "block", margin: "0 auto", color: `${theme.palette.icon.primary}`, verticalAlign: "middle", fontSize: "3rem", mt: 5, mb: 1, fontWeight: 300 }}>
          rocket_launch
        </Icon>
        <Typography variant="title">{
          !isLifeTimeMember ? "Try Stanza+ free for 7 days" : "Current Plan: Lifetime Membership"
        }
        </Typography>
        <Typography variant="subtitle2" sx={{ mb: 3, color: "text.secondary" }}>
          {
            !isLifeTimeMember ? "Then just $30 a year after your trial." : "Your plan includes all these features."
          }
        </Typography>
        <Stack sx={{ margin: "auto", maxWidth: "360px", textAlign: "center", gap: 1 }}>

          <Box display="flex" flexDirection="column" pl={3} gap={1} mb={3}>
            <Typography variant="body1" sx={{ mb: 1, textAlign: "left" }}>
              <Icon baseClassName="material-symbols-outlined" sx={{ color: `${theme.palette.icon.primary}`, verticalAlign: "bottom", mr: "10px", fontWeight: 300 }}>
                all_inclusive
              </Icon>
              Unlimited calendar subscriptions
            </Typography>
            <Typography variant="body1" sx={{ mb: 1, textAlign: "left" }}>
              <Icon baseClassName="material-symbols-outlined" sx={{ color: `${theme.palette.icon.primary}`, verticalAlign: "bottom", mr: "10px", fontWeight: 300 }}>
                folder_supervised
              </Icon>
              Create and share groups
            </Typography>
            <Typography variant="body1" sx={{ mb: 1, textAlign: "left" }}>
              <Icon baseClassName="material-symbols-outlined" sx={{ color: `${theme.palette.icon.primary}`, verticalAlign: "bottom", mr: "10px", fontWeight: 300 }}>
                download
              </Icon>
              Download individual events
            </Typography>
            <Typography variant="body1" sx={{ mb: 1, textAlign: "left" }}>
              <Icon baseClassName="material-symbols-outlined" sx={{ color: `${theme.palette.icon.primary}`, verticalAlign: "bottom", mr: "10px", fontWeight: 300 }}>
                business_messages
              </Icon>
              ChatGPT for discovering events
            </Typography>
            <Typography variant="body1" sx={{ mb: 1, textAlign: "left" }}>
              <Icon baseClassName="material-symbols-outlined" sx={{ color: `${theme.palette.icon.primary}`, verticalAlign: "bottom", mr: "10px", fontWeight: 300 }}>
                support_agent
              </Icon>
              24/5 customer service
            </Typography>
            <Typography variant="body1" sx={{ mb: 1, textAlign: "left" }}>
              <Icon baseClassName="material-symbols-outlined" sx={{ color: `${theme.palette.icon.primary}`, verticalAlign: "bottom", mr: "10px", fontWeight: 300 }}>
                insights
              </Icon>
              Personalized recommendations
            </Typography>
            <Typography variant="body1" sx={{ mb: 1, textAlign: "left" }}>
              <Icon baseClassName="material-symbols-outlined" sx={{ color: `${theme.palette.icon.primary}`, verticalAlign: "bottom", mr: "10px", fontWeight: 300 }}>
                ad_units
              </Icon>
              Early access to mobile app
            </Typography>
            <Typography variant="body1" sx={{ textAlign: "left" }}>
              <Icon baseClassName="material-symbols-outlined" sx={{ color: `${theme.palette.icon.primary}`, verticalAlign: "bottom", mr: "10px", fontWeight: 300 }}>
                featured_seasonal_and_gifts
              </Icon>
              Early access to new features
            </Typography>
          </Box>
          <LoadingButton
            data-testid="consumer-upgrade-button"
            size="shrinked"
            variant="contained"
            loading={isLoading}
            color="primary"
            isLoading={isLoading}
            onClick={!isLifeTimeMember ? handleUpgrade : handleGoHome}
          >
            {
              !isLifeTimeMember ? "Start Free Trial" : "Go home"
            }
          </LoadingButton>
          {
            !isLifeTimeMember &&
            <Typography
              variant="subtitle2"
              sx={{ pt: 0.5, margin: "auto", color: "text.secondary" }}
            >
              Billed $0 today. No commitment, cancel anytime.
            </Typography>
          }

          <Divider sx={{ my: 2.5 }} />

          <Icon baseClassName="material-symbols-outlined" sx={{ display: "block", margin: "0 auto", color: `${theme.palette.icon.primary}`, verticalAlign: "middle", fontSize: "3rem", mb: 1, fontWeight: 300 }}>
            timelapse
          </Icon>
          <Typography variant="title" sx={{ width: "80%", margin: "0 auto" }}>How much is your time worth to you?</Typography>
          <CommentsList comments={[{
            text: "If it wasn't for Stanza, I'd be spending hours manually adding all my teams' events into my calendar. Now it just updates every season and I know I won't miss out!",
            src: User1,
          }]} />
          <TimeSaveBlock handleUpgrade={handleUpgrade} handleGoHome={handleGoHome} isLifeTimeMember={isLifeTimeMember} />

          <Divider sx={{ my: 2.5 }} />

          <Stack spacing={1} direction="row" sx={{ display: "block", margin: "0 auto", mb: 1 }}>
            <Icon baseClassName="material-symbols-outlined" sx={{ color: `${theme.palette.icon.primary}`, verticalAlign: "middle", fontSize: "3rem", fontWeight: 300 }}>
              favorite_border
            </Icon>
            <Icon baseClassName="material-symbols-outlined" sx={{ color: `${theme.palette.icon.primary}`, verticalAlign: "middle", fontSize: "3rem", fontWeight: 300 }}>
              favorite_border
            </Icon>
            <Icon baseClassName="material-symbols-outlined" sx={{ color: `${theme.palette.icon.primary}`, verticalAlign: "middle", fontSize: "3rem", fontWeight: 300 }}>
              favorite_border
            </Icon>
          </Stack>
          <Typography variant="title">All the love for Stanza+</Typography>
          <CommentsList comments={[{
            text: "The ability to group all my sports calendars is AMAZING!!! I just have one calendar I add to my Google Calendar and don't have to deal with the clutter of a giant list in my sidebar.",
            src: User1,
          }, {
            text: "With discovery, I can find calendars based on artists, actors, players and so much more. It makes it so much easier for me to find what I'm looking for.",
            src: User2,
          }, {
            text: "I really enjoy your product and how easy it is to use. Having it for sports schedules and my favorite TV shows makes life easier. I especially love with TV shows that I don't have to hunt down each week whether it's a new episode or not.",
            src: User3,
          }, {
            text: "Your sports calendars on my iPhone are awesome. In fact, I sometimes think they are magical. Stanza changed the Yankees home opener (the day before the actual event due to an impending storm) before I even knew about the date change. ",
            src: User4,
          }]} />

          <Divider sx={{ my: 2.5 }} />

          <Icon baseClassName="material-symbols-outlined" sx={{ display: "block", margin: "0 auto", color: `${theme.palette.icon.primary}`, verticalAlign: "middle", fontSize: "3rem", mb: 1, fontWeight: 300 }}>
            rocket_launch
          </Icon>
          <Typography variant="title">{
            !isLifeTimeMember ? "Ready to put Stanza+ to the test?" : "Current Plan: Lifetime Membership"
          }
          </Typography>
          <LoadingButton
            size="shrinked"
            variant="contained"
            color="primary"
            loading={isLoading}
            onClick={!isLifeTimeMember ? handleUpgrade : handleGoHome}
            sx={{ mt: 2 }}
          >
            {
              !isLifeTimeMember ? "Start Free Trial" : "Go home"
            }
          </LoadingButton>
          {
            !isLifeTimeMember &&
            <Typography
              variant="subtitle2"
              sx={{ pt: 0.5, margin: "auto", color: "text.secondary", mb: 4 }}
            >
              Billed $0 today. No commitment, cancel anytime.
            </Typography>
          }

          {/* <Typography
            variant="footer"
            sx={{ mt: 2, mb: 1, textAlign: "center", fontSize: "11px", lineHeight: "14.85px" }}
          >
            Your plan starts as soon as you set up payment. We'll renew your plan for you (unless you cancel 24 hours before the end of your billing cycle). You can manage your subscription under Your Plan.
          </Typography> */}

        </Stack>
      </Box>
      <Footer />
    </div >
  );
}

export default ConsumerUpgrade;
